import './Faq.css'
import { useEffect, useRef } from 'react'

const Faq = () => {
    const effectRan = useRef(false)

    useEffect(() => {
        if (effectRan.current === false) {
            const faq = document.getElementsByClassName('openDescription')
            for (let i = 0; i < faq.length; i++) {
                faq[i].addEventListener('click', () => {
                    if (faq[i].classList.contains('active')) {
                        faq[i].classList.remove('active')
                    } else {
                        faq[i].classList.add('active')
                    }
                })
            }
        }
        return(() => effectRan.current = true)
    }, [])

    return (
        <div>
            <section className='faqContainer'>
                <div className="container px-6 pt-12 pb-24 mx-auto">
                    <h2 className="text-5xl text-center font-semibold text-gray-800 dark:text-white faqTitle">Preguntas frecuentes</h2>
                    <div className="mt-8 space-y-8 lg:mt-12">
                        <div className="faqItem rounded-lg qContainer">
                            <button className="flex p-4 items-center justify-between w-full openDescription">
                                <h3 className="font-semibold text-gray-700 dark:text-white text-xl">¿Cómo contactarnos?</h3>
                                <img className='w-8' src='media/plus.svg' alt='abrir' />
                            </button>
                            <p className="text-md px-4 text-gray-500 dark:text-gray-300 descriptionParagraph">
                                Los medios de contacto son los siguientes: <br />
                                <ul className='pl-5'>
                                    <li>- Correo electrónico: <a className='text-blue-400 underline' href='mailto:dieselavenida@dieselavenida.com.ar' target='_blank' rel="noreferrer">dieselavenida@dieselavenida.com.ar</a></li>
                                    <li>- Teléfonos: 011 4137-7673 | 011 4137-7674 | 011 4657-2247</li>
                                    <li>- Whatsapp: <a className='text-blue-400 underline' href='https://wa.me/+541561943828?text=¡Hola! Mi número de chasis es...' target='_blank' rel="noreferrer">+54 1561943828</a></li>
                                </ul>
                                <p className='mt-5'>Por favor, de ser posible aclarar el número de chasis en caso de ser necesario, para poder acelerar el proceso.</p>
                            </p>
                        </div>
                        <div className="faqItem rounded-lg">
                            <button className="flex p-4 items-center justify-between w-full openDescription">
                                <h3 className="font-semibold text-gray-700 dark:text-white text-xl">Dónde encontrarnos</h3>
                                <img className='w-8' src='media/plus.svg' alt='abrir' />
                            </button>
                            <p className="text-md px-4 text-gray-500 dark:text-gray-300 descriptionParagraph">
                                Av. Díaz Vélez 414, Ciudadela, Provincia de Buenos Aires
                            </p>
                        </div>
                        <div className="faqItem rounded-lg">
                            <button className="flex p-4 items-center justify-between w-full openDescription">
                                <h3 className="font-semibold text-gray-700 dark:text-white text-xl">Nuestros horarios</h3>
                                <img className='w-8' src='media/plus.svg' alt='abrir' />
                            </button>
                            <p className="text-md px-4 text-gray-500 dark:text-gray-300 descriptionParagraph">
                                Lunes a viernes de 8:30 a 12:30, 14:00 a 17:00 <br/>
                                Sábados de 8:30 a 12:30 <br/>
                                Domingo cerrado
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Faq