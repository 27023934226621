import './Contact.css'

const Contact = () => {
    return (
        <div className='contactContainer' id='contact'>
            <div className='contactSection'>
                <div className='contactData'>
                    <h3>Datos de contacto</h3>
                    <div className='contactFlex'>
                        <a className='cFlexElement' href='https://wa.me/+541561943828?text=¡Hola! Mi número de chasis es...' target='_blank' rel="noreferrer">
                            <img src='media/whatsapp-icon.svg' alt='whatsapp' />
                            <p>+54 1561943828</p>
                        </a>
                        <div className='cFlexElement'>
                            <img src='media/phone-icon.svg' alt='telefono' title='011 4137-7673' />
                            <div>
                                <p>011 4137-7673</p>
                                <p>011 4137-7674</p>
                                <p>011 4657-2247</p>
                            </div>
                        </div>
                        <a className='cFlexElement' href='mailto:dieselavenida@dieselavenida.com.ar' target='_blank' rel="noreferrer">
                            <img src='media/email-icon.svg' alt='email' />
                            <p>dieselavenida@dieselavenida.com.ar</p>
                        </a>
                        <a className='cFlexElement' href='https://www.google.com/maps/place/Av.+D%C3%ADaz+V%C3%A9lez+414,+Ciudadela,+Provincia+de+Buenos+Aires,+Argentina/@-34.645069,-58.541996,16z/data=!4m6!3m5!1s0x95bcc86d6ecc3645:0xcb2a4e985c2ceeef!8m2!3d-34.645069!4d-58.541996!16s%2Fg%2F11c26fpfk9?hl=es' target='_blank' rel="noreferrer">
                            <img src='media/location.svg' alt='localizacion' />
                            <p>Av. Díaz Vélez 414, Ciudadela</p>
                        </a>
                    </div>
                </div>
                <div className='contactIframe'>
                    <iframe title='Diesel Avenida'
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Av.%20D%C3%ADaz%20V%C3%A9lez%20414,%20Ciudadela,%20Provincia%20de%20Buenos%20Aires+(Diesel%20Avenida)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        width="100%" height="700px"><a href="https://www.gps.ie/car-satnav-gps/">Car Navigation Systems</a>
                    </iframe>
                </div>
            </div>
        </div>
    )
}

export default Contact