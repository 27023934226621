import './Footer.css'

const Footer = () => {
    return (
        <div className='footerContainer'>
            <img src='media/logo.png' alt='logo' />
            <p>
                Diesel Avenida 2024 - Todos los derechos reservados ©
            </p>
            <div className='navbarFooterContainer'>
                <nav className='stroke'>
                    <ul className='flexFooterNavigation'>
                        <li><a href='/#'>INICIO</a></li>
                        <li><a href='/#products'>PRODUCTOS</a></li>
                        <li><a href='/#us'>NOSOTROS</a></li>
                        <li><a href='/#contact'>CONTACTO</a></li>
                    </ul>
                </nav>
            </div>
            <div className='footerSocials'>
                <a href='https://wa.me/+541561943828?text=¡Hola! Mi número de chasis es...' target='_blank' rel="noreferrer">
                    <img src='media/whatsapp-icon.svg' alt='whatsapp' />
                </a>
                <a href='mailto:dieselavenida@dieselavenida.com.ar' target='_blank' rel="noreferrer">
                    <img src='media/email-icon.svg' alt='email' />
                </a>
            </div>
        </div>
    )
}

export default Footer
